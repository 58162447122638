/**
 * Const with params element
 * Hero type1 component
 * Display the hero type 1: h1 +  background-img
 */
/* Import section */
import React from "react"
import "../styles/components/herotype1.scss"
import Ctabutton from "./ctabutton"

/* Declaration function */
const Herotype1 = ( { h1title, Intro, ctaLink, ctaLabel} ) => {
  return (
      <div className="wrap-hero-type1">
        <div className="box-content-hero-type1">
          <div className="box-hero">
            <h1 dangerouslySetInnerHTML={{__html:h1title}} />
            <div className="text-intro" dangerouslySetInnerHTML={{__html:Intro}} />
            <Ctabutton ctaLink={ctaLink} ctaLabel={ctaLabel} />
          </div>
        </div>
        <div className="box-visual-hero-type1"></div>
      </div>
  )
}

/* Export function */
export default Herotype1


/**
 * Index page
 */
/* Import section */

import "../styles/pages/index.scss"

import * as React from "react"

import { I18nextContext, useTranslation } from "gatsby-plugin-react-i18next"

import Blockteam from "../components/blockteam"
import Ctabutton from "../components/ctabutton"
import Herotype1 from "../components/herotype1"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"

/* Declaration */
const IndexPage = ({ data }) => {

  //Const data
  const dataPage = data.wpPage
  const {t} = useTranslation()
  const context = React.useContext(I18nextContext)
  var cLangSlug = context.language
  var cLang = (cLangSlug === "en") ? "En" : "Fr"

  //Variables of the query
  var cAcf = "acfPageHomepage"+cLang
  var cTitleH1 = "titleH1Homepage"+cLang
  var cIntro = "introHomepage"+cLang
  var cTitleBanner = "titleBanner"+cLang
  var cTextBanner = "textBanner"+cLang
  var cCtaBanner = "ctaBanner"+cLang
  var cTitleAbout = "titleAbout"+cLang
  var cTextAbout = "textAbout"+cLang
  var cTitleMission = "titleMission"+cLang
  var cTextMission = "textMission"+cLang
  var cCitationMission = "citationMission"+cLang
  var cTitleTeam = "titleTeam"+cLang
  var cMemberTeam = "memberTeam"+cLang
  const dataAcf = dataPage[cAcf]
  const titleH1 = dataAcf[cTitleH1]
  const intro = dataAcf[cIntro]
  const titleBanner = dataAcf[cTitleBanner]
  const textBanner = dataAcf[cTextBanner]
  const ctaBanner = dataAcf[cCtaBanner]
  const titleAbout = dataAcf[cTitleAbout]
  const textAbout = dataAcf[cTextAbout]
  const titleMission = dataAcf[cTitleMission]
  const textMission = dataAcf[cTextMission]
  const citationMission = dataAcf[cCitationMission]
  const memberTeam = dataAcf[cMemberTeam]
  const titleTeam = dataAcf[cTitleTeam]

  return(
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc} />

      <section className="section-hero-homepage">
        <Herotype1 h1title={titleH1} Intro={intro} ctaLink={t('slugitem-nav-solutions')} ctaLabel={t('ctaHero')} />
      </section>

      <section className="section-banner">
        <div className="wrap-maincontent">
          <div className="content-banner flex-box">
            <h2>{titleBanner}</h2>
            <div className="text-banner" dangerouslySetInnerHTML={{__html:textBanner}} />
            <Ctabutton ctaLabel={ctaBanner} ctaLink={t('slugitem-nav-careers')} colorStyle="white-format" />
          </div>
        </div>
      </section>

      <section id="about" className="section-about">
        <div className="wrap-centercontent">
          <div className="content-about centercontent">
            <h2>{titleAbout}</h2>
            <div className="text-about" dangerouslySetInnerHTML={{__html:textAbout}} />
            <Ctabutton ctaLink={t('slugitem-nav-solutions')} ctaLabel={t('item-nav-solutions')} />
          </div>
        </div>
      </section>

      <section className="section-citation">
        <div className="wrap-centercontent">
          <div className="citation centercontent">
            <blockquote>{citationMission}</blockquote>
            <p>Nathanial Findlay</p>
          </div>
        </div>
      </section>

      <section className="section-mission">
        <div className="wrap-centercontent">
          <div className="content-mission centercontent">
            <h2>{titleMission}</h2>
            <div className="text-mission" dangerouslySetInnerHTML={{__html:textMission}} />
          </div>
        </div>
      </section>

      <section className="section-team">
        <div className="wrap-maincontent">
          <div className="content-team">
            <h2>{titleTeam}</h2>
            <div className="box-team">
              {memberTeam.map((team, index) =>
                <Blockteam
                  keyTeam={index}
                  nameTeam={team.nameMember}
                  titleTeam={team.jobMember}
                  pictureTeam={team.pictureMember.localFile.childImageSharp.gatsbyImageData}
                  linkedinTeam={team.linkedinMember}
                />
              )}
            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    wpPage(slug: {regex: "/homepage/"}, language: {slug: {eq: $language}}) {
      acfPageHomepageEn {
        titleH1HomepageEn
        introHomepageEn
        titleBannerEn
        textBannerEn
        ctaBannerEn
        titleAboutEn
        textAboutEn
        titleMissionEn
        textMissionEn
        citationMissionEn
        titleTeamEn
        memberTeamEn {
          jobMember
          linkedinMember
          nameMember
          pictureMember {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, WEBP], width: 200)
              }
            }
          }
        }
      }
      acfPageHomepageFr {
        titleH1HomepageFr
        introHomepageFr
        titleBannerFr
        textBannerFr
        ctaBannerFr
        titleAboutFr
        textAboutFr
        titleMissionFr
        textMissionFr
        citationMissionFr
        titleTeamFr
        memberTeamFr {
          jobMember
          linkedinMember
          nameMember
          pictureMember {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, WEBP], width: 200)
              }
            }
          }
        }

      }
      seo {
        metaDesc
        title
      }
    }
  }
`

export default IndexPage

/**
 * Const with params element
 * Block team component
 * Display a block of team leadership
 */
/* Import section */
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { IconContext } from "react-icons"
import { BsLinkedin } from "react-icons/bs"
import "../styles/components/blockteam.scss"

/* Declaration function */
const Blockteam= ( { keyTeam, nameTeam, titleTeam, pictureTeam, linkedinTeam} ) => {
  return (
    <div key={keyTeam} className="block-team">
      <GatsbyImage image={pictureTeam} alt={`Myca team member: ${nameTeam}`} />
      <div className="box-title-team">
        <h3>{nameTeam}</h3>
        <p>{titleTeam}</p>
        <a href={linkedinTeam} target="_blank" rel="noreferrer" className="cta-linkedin">
          <span>
            <IconContext.Provider value={{ className: "svg-linkedin" }}>
              <BsLinkedin />
            </IconContext.Provider>
          </span>
        </a>
      </div>
    </div>
  )
}

/* Export function */
export default Blockteam
